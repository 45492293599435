<script>
/* eslint-env browser */
/* global FB */
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAlert } from 'dashboard/composables';
import { useAccount } from 'dashboard/composables/useAccount';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import PageHeader from '../../SettingsSubPageHeader.vue';
import router from '../../../../index';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { loadScript } from 'dashboard/helper/DOMHelpers';
import * as Sentry from '@sentry/browser';
import { mapGetters } from 'vuex';
import ChannelApi from '../../../../../api/channels';
export default {
  name: 'FacebookInstagramSetup',
  components: {
    LoadingState,
    PageHeader,
  },
  mixins: [globalConfigMixin],
  setup() {
    const { accountId } = useAccount();
    return {
      accountId,
      v$: useVuelidate(),
    };
  },
  data() {
    return {

      isCreating: false,
      hasError: false,
      hasLoginStarted: false,

      user_access_token: '',
      pageList: [],
      selectedPage: { name: null, id: null },
      pageName: '',


      currentStep: 'facebookLogin',

      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      errorStateMessage: '',
      errorStateDescription: '',

      instagramError: '',
      instagramPopup: null,
    };
  },
  validations: {
    pageName: { required },
    selectedPage: {

      isEmpty() {
        return this.selectedPage !== null && !!this.selectedPage.name;
      },
    },
  },
  computed: {


    showLoader() {
      return !this.user_access_token || this.isCreating;
    },

    getSelectablePages() {
      return this.pageList.filter(item => !item.exists);
    },
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {

    window.fbAsyncInit = this.runFBInit;

    window.addEventListener('message', this.handleInstagramAuth);
  },
  beforeUnmount() {

    window.removeEventListener('message', this.handleInstagramAuth);
  },
  methods: {
    /*
     * =============== Login com Facebook ===============
     */
    async startLogin() {
      this.hasLoginStarted = true;
      try {
        await this.loadFBsdk();
        this.runFBInit();
        this.tryFBlogin();
      } catch (error) {
        if (error.name === 'ScriptLoaderError') {
          useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_LOADING'));
        } else {
          Sentry.captureException(error);
          useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
        }
      }
    },
    async loadFBsdk() {
      return loadScript('https://connect.facebook.net/en_US/sdk.js', {
        id: 'facebook-jssdk',
      });
    },
    runFBInit() {
      FB.init({
        appId: window.chatwootConfig.fbAppId,
        xfbml: true,
        version: window.chatwootConfig.fbApiVersion,
        status: true,
      });
      window.fbSDKLoaded = true;
      FB.AppEvents.logPageView();
    },
    tryFBlogin() {
      FB.login(
        response => {
          this.hasError = false;
          if (response.status === 'connected') {
            this.fetchPages(response.authResponse.accessToken);
          } else if (response.status === 'not_authorized') {

            this.hasError = true;
            this.errorStateMessage = this.$t('INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED');
            this.errorStateDescription = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED_HELP'
            );
          } else {

            this.hasError = true;
            this.errorStateMessage = this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH');
          }
        },
        {
          scope:
            'pages_manage_metadata,business_management,pages_messaging,instagram_basic,pages_show_list,pages_read_engagement,instagram_manage_messages',
        }
      );
    },
    async fetchPages(accessToken) {
      try {
        const response = await ChannelApi.fetchFacebookPages(accessToken, this.accountId);
        const {
          data: { data },
        } = response;
        this.pageList = data.page_details;
        this.user_access_token = data.user_access_token;

        this.currentStep = 'instagramPrompt';
      } catch (error) {

      }
    },
    /*
     * =============== Passo Intermediário: Conectar Instagram ou Pular ===============
     */
    connectInstagram() {
      const instagramAuthUrl =
        'https://www.instagram.com/oauth/authorize?' +
        'enable_fb_login=0&force_authentication=1&' +
        `client_id=${window.chatwootConfig.igAppId}&` +
        `redirect_uri=${window.chatwootConfig.hostURL}/instagram/callback&` +
        'response_type=code&' +
        'scope=instagram_business_basic%2Cinstagram_business_manage_messages%2C' +
        'instagram_business_manage_comments%2Cinstagram_business_content_publish';
      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;
      this.instagramPopup = window.open(
        instagramAuthUrl,
        'instagramPopup',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      const timer = setInterval(() => {
        if (!this.instagramPopup || this.instagramPopup.closed) {
          clearInterval(timer);

          if (!this.instagramConnected) {
            this.instagramError = 'Login com Instagram cancelado ou não finalizado.';
          }
        }
      }, 500);
    },
    skipInstagram() {


      this.currentStep = 'pageSelection';
    },
    handleInstagramAuth(event) {


      const { success, error } = event.data || {};

      if (typeof success !== 'undefined') {
        if (success) {

          this.instagramError = '';

          this.currentStep = 'pageSelection';
        } else {

          this.instagramError = error || 'Falha ao conectar com o Instagram.';
        }

        if (this.instagramPopup && !this.instagramPopup.closed) {
          this.instagramPopup.close();
        }
      }
    },
    /*
     * =============== Passo Final: Selecionar página e criar canal ===============
     */
    setPageName({ name }) {
      this.v$.selectedPage.$touch();
      this.pageName = name;
    },
    channelParams() {
      return {
        user_access_token: this.user_access_token,
        page_access_token: this.selectedPage.access_token,
        page_id: this.selectedPage.id,
        inbox_name: this.selectedPage.name,
      };
    },
    createChannel() {
      this.v$.$touch();
      if (!this.v$.$error) {
        this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
        this.isCreating = true;

        this.$store
          .dispatch('inboxes/createFBChannel', this.channelParams())
          .then(data => {

            router.replace({
              name: 'settings_inboxes_add_agents',
              params: { page: 'new', inbox_id: data.id },
            });
          })
          .catch(() => {
            this.isCreating = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.error-message {
  color: red;
  margin: 8px 0;
}
</style>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <!-- PASSO 0: Aguardar o usuário iniciar login com Facebook -->
    <div
      v-if="!hasLoginStarted"
      class="flex flex-col items-center justify-center h-full text-center"
    >
      <a href="#" @click.prevent="startLogin">
        <img
          class="w-auto h-10"
          src="~dashboard/assets/images/channels/facebook_login.png"
          alt="Facebook-logo"
        />
      </a>
      <p class="py-6">
        {{
          useInstallationName(
            $t('INBOX_MGMT.ADD.FB.HELP'),
            globalConfig.installationName
          )
        }}
      </p>
    </div>
    <!-- Exibir erro de autenticação do Facebook -->
    <div v-else-if="hasError" class="max-w-lg mx-auto text-center">
      <h5>{{ errorStateMessage }}</h5>
      <p v-if="errorStateDescription" v-dompurify-html="errorStateDescription" />
    </div>
    <!-- Exibir estado de carregamento se ainda não temos user_access_token ou se está criando canal -->
    <LoadingState v-else-if="showLoader" :message="emptyStateMessage" />
    <!-- Caso contrário, seguimos nos passos internos -->
    <div v-else>
      <!-- PASSO 1: Prompt de "Conectar com Instagram" ou "Pular" -->
      <div v-if="currentStep === 'instagramPrompt'" class="flex flex-col items-center text-center justify-center">
        <h3>{{ $t('INBOX_MGMT.ADD.INSTAGRAM.CONNECT_OR_SKIP') }}</h3>
        <p class="my-2">
          {{ $t('INBOX_MGMT.ADD.INSTAGRAM.CONNECT_OR_SKIP_DESCRIPTION') }}
        </p>

        <a
          href="#"
          class="mb-4 inline-block"
          @click.prevent="connectInstagram"
        >
          <img
            src="~dashboard/assets/images/channels/sign_with_instagram.png"
            alt="Login with Instagram"
            class="w-auto h-10"
          />
        </a>

        <button
          class="mt-auto text-xs text-gray-500 hover:text-gray-700"
          @click="skipInstagram"
        >
          {{ $t('INBOX_MGMT.ADD.INSTAGRAM.SKIP_BUTTON') }}
        </button>
        <!-- Se houve algum erro específico ao conectar Instagram, exibir -->
        <p v-if="instagramError" class="text-red-500 mt-4">{{ instagramError }}</p>
      </div>
      <!-- PASSO 2: Seleção de Página e Nome da Inbox (Facebook) -->
      <form
        v-if="currentStep === 'pageSelection'"
        class="flex flex-wrap mx-0"
        @submit.prevent="createChannel"
      >
        <div class="w-full">
          <PageHeader
            :header-title="$t('INBOX_MGMT.ADD.DETAILS.TITLE')"
            :header-content="
              useInstallationName(
                $t('INBOX_MGMT.ADD.DETAILS.DESC'),
                globalConfig.installationName
              )
            "
          />
        </div>
        <div class="w-3/5">
          <div class="w-full">
            <div class="input-wrap" :class="{ error: v$.selectedPage.$error }">
              {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PAGE') }}
              <multiselect
                v-model.trim="selectedPage"
                close-on-select
                allow-empty
                :options="getSelectablePages"
                track-by="id"
                label="name"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE')"
                selected-label
                @select="setPageName"
              />
              <span v-if="v$.selectedPage.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER') }}
              </span>
            </div>
          </div>
          <div class="w-full mt-4">
            <label :class="{ error: v$.pageName.$error }">
              {{ $t('INBOX_MGMT.ADD.FB.INBOX_NAME') }}
              <input
                v-model.trim="pageName"
                type="text"
                :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_NAME')"
                @input="v$.pageName.$touch"
              />
              <span v-if="v$.pageName.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.ADD_NAME') }}
              </span>
            </label>
          </div>
          <div class="w-full text-right mt-4">
            <input
              type="submit"
              :value="$t('INBOX_MGMT.ADD.INSTAGRAM.SUBMIT_BUTTON')"
              class="button"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
