var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"},[(!_vm.hasLoginStarted)?_c('div',{staticClass:"flex flex-col items-center justify-center h-full text-center"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.startLogin.apply(null, arguments)}}},[_c('img',{staticClass:"w-auto h-10",attrs:{"src":require("dashboard/assets/images/channels/facebook_login.png"),"alt":"Facebook-logo"}})]),_vm._v(" "),_c('p',{staticClass:"py-6"},[_vm._v("\n      "+_vm._s(_vm.useInstallationName(
          _vm.$t('INBOX_MGMT.ADD.FB.HELP'),
          _vm.globalConfig.installationName
        ))+"\n    ")])]):(_vm.hasError)?_c('div',{staticClass:"max-w-lg mx-auto text-center"},[_c('h5',[_vm._v(_vm._s(_vm.errorStateMessage))]),_vm._v(" "),(_vm.errorStateDescription)?_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.errorStateDescription),expression:"errorStateDescription"}]}):_vm._e()]):(_vm.showLoader)?_c('LoadingState',{attrs:{"message":_vm.emptyStateMessage}}):_c('div',[(_vm.currentStep === 'instagramPrompt')?_c('div',{staticClass:"flex flex-col items-center text-center justify-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.INSTAGRAM.CONNECT_OR_SKIP')))]),_vm._v(" "),_c('p',{staticClass:"my-2"},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.INSTAGRAM.CONNECT_OR_SKIP_DESCRIPTION'))+"\n      ")]),_vm._v(" "),_c('a',{staticClass:"mb-4 inline-block",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.connectInstagram.apply(null, arguments)}}},[_c('img',{staticClass:"w-auto h-10",attrs:{"src":require("dashboard/assets/images/channels/sign_with_instagram.png"),"alt":"Login with Instagram"}})]),_vm._v(" "),_c('button',{staticClass:"mt-auto text-xs text-gray-500 hover:text-gray-700",on:{"click":_vm.skipInstagram}},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.INSTAGRAM.SKIP_BUTTON'))+"\n      ")]),_vm._v(" "),(_vm.instagramError)?_c('p',{staticClass:"text-red-500 mt-4"},[_vm._v(_vm._s(_vm.instagramError))]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.currentStep === 'pageSelection')?_c('form',{staticClass:"flex flex-wrap mx-0",on:{"submit":function($event){$event.preventDefault();return _vm.createChannel.apply(null, arguments)}}},[_c('div',{staticClass:"w-full"},[_c('PageHeader',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.DETAILS.TITLE'),"header-content":_vm.useInstallationName(
              _vm.$t('INBOX_MGMT.ADD.DETAILS.DESC'),
              _vm.globalConfig.installationName
            )}})],1),_vm._v(" "),_c('div',{staticClass:"w-3/5"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"input-wrap",class:{ error: _vm.v$.selectedPage.$error }},[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.FB.CHOOSE_PAGE'))+"\n            "),_c('multiselect',{attrs:{"close-on-select":"","allow-empty":"","options":_vm.getSelectablePages,"track-by":"id","label":"name","select-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_SELECT'),"deselect-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_REMOVE'),"placeholder":_vm.$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE'),"selected-label":""},on:{"select":_vm.setPageName},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedPage"}}),_vm._v(" "),(_vm.v$.selectedPage.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER'))+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"w-full mt-4"},[_c('label',{class:{ error: _vm.v$.pageName.$error }},[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.FB.INBOX_NAME'))+"\n            "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.pageName),expression:"pageName",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.FB.PICK_NAME')},domProps:{"value":(_vm.pageName)},on:{"input":[function($event){if($event.target.composing)return;_vm.pageName=$event.target.value.trim()},_vm.v$.pageName.$touch],"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.v$.pageName.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.FB.ADD_NAME'))+"\n            ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"w-full text-right mt-4"},[_c('input',{staticClass:"button",attrs:{"type":"submit"},domProps:{"value":_vm.$t('INBOX_MGMT.ADD.INSTAGRAM.SUBMIT_BUTTON')}})])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }